<template>
  <v-app id="login">
    <div class="login-page">
      <v-form ref="loginForm">
        <div class="logo-main">
          <img src="../assets/logo-main.svg" alt="" />
        </div>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              autocomplete="username"
              :label="$t('login.email')"
              v-model="email"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
              :error-messages="emailErrors"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              autocomplete="new-password"
              type="password"
              outlined
              :label="$t('login.password')"
              v-model="password"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
              :error-messages="passwordErrors"
            ></v-text-field>
          </v-col>
          <v-col class="d-flex" cols="12" sm="6" xsm="12"> </v-col>
          <v-spacer></v-spacer>
          <v-col class="d-flex justify-center" cols="12" sm="12" xsm="12">
            <v-btn
              @click="doLogin"
              :disabled="$v.$invalid && $v.$anyDirty"
              color="#1b5e20"
              class="white--text"
              large
              >{{ $t("login.loginBtn") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-app>
</template>
<script>
import { mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { showSnackbar } from "../eventbus/action.js";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      errors: {
        email: null,
        password: null,
      },
    };
  },
  validations: {
    email: { required, email },
    password: { required },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email &&
        errors.push(this.$t("login.validations.emailIsInvalid"));
      !this.$v.email.required &&
        errors.push(this.$t("login.validations.emailIsRequired"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push(this.$t("login.validations.passwordIsRequired"));
      return errors;
    },
  },
  methods: {
    ...mapActions({
      login: "user/login",
    }),
    async doLogin() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.commit("setLoader", true);
        try {
          await this.login({
            email: this.email,
            password: this.password,
          });
          this.$router.push({ name: "Dashboard" });
        } catch ({ message }) {
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showSnackbar(errorMessage);
          this.errors.email = message.email;
          this.errors.password = message.password;
        } finally {
          this.$store.commit("setLoader", false);
        }
      }
    },
  },
};
</script>
